<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return { };
  }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="contentType" 
    v-if="isContentVisibleToUser()" 
    class="position-relative" 
    page-with-header-component>
    <!-- margin management -->
    <div class="row align-items-center flex-column mx-0 header py-5">
      <div v-if="content.breadcrumb" class="col-12 col-md-6">
        <breadcrumb/>
      </div>
      <component :is="content.title_tag || 'h2'" class="h1 page-header-title col-12 col-md-6">{{content.title}}</component>
      <component :is="content.description_tag || 'div'" v-html="content.description" class="page-header-description col-12 col-md-6"></component>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss">
</style>

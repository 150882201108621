<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from "lodash";
import Intersect from 'vue-intersect';
import 'odometer/themes/odometer-theme-default.css'
const Odometer = require('odometer');

// shared css variables
const DEFAULT_TEXT_DIM = 15;
const DEFAULT_DOT_DIM = .75;
const DEFAULT_ANIMATION_DURATION = 2;
 
export default {
  mixins: [fandomBasicMixin],
  components: { Intersect },
  data() {
    return {
      animationDone: false,
    }
  },
  mounted() {
    Vue.nextTick(() => {
      this.$el.style.setProperty('--text-dim', `${DEFAULT_TEXT_DIM}rem`);
      this.$el.style.setProperty('--dot-dim', `${DEFAULT_DOT_DIM}rem`);
      this.$el.style.setProperty('--animation-duration', `${DEFAULT_ANIMATION_DURATION}s`);
    })
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    toggleVideo(status) {
      const video = $(this.$refs.video)[0];
      if (status) {
        video.muted = true;
        video.play();
      } else {
        video.pause();
      }
    },
    initAnimations() {
      if (document.readyState === 'complete' && !this.animationDone) {
        this.animationDone = true;
        Vue.nextTick(() => {
          $(".point__line").toggleClass("animated-line");
          setTimeout(() => {
            $(".point__dot").toggleClass("animated-dot");
          }, DEFAULT_ANIMATION_DURATION*1000);
          const elements = document.querySelectorAll('.point__odometer-number');
          elements.forEach((el, index) => {
            let od = new Odometer({
              el: el,
              value: 0,
              // Any option (other than auto and selector) can be passed in here
              format: '(ddd)',
              theme: 'default'
            });
            od.update(this.pointValue(this.content.points[index]));
          });
        });
      } else {
        document.onreadystatechange = this.initAnimations;
      }
    },
    pointStyle(point) {
      const style = { "text-align": point.align };
      if (point.align === "left") {
        style["left"] = 0;
      }
      if (point.align === "right") {
        style["right"] = 0;
      }
      style["top"] = `calc(${point.coord_y.replace('%', '')}% - ${DEFAULT_TEXT_DIM/2}rem)`;
      return style;
    },
    dotContainerStyle(point) {
      const style = { 'top': `${point.coord_y}%`};
      if (point.align == "left") {
        style["left"] = '0';
        style["right"] = `${100 - parseFloat(point.coord_x)}%`;
      }
      if (point.align == "right") {
        style["left"] = `${point.coord_x.replace('%', '')}%`;
        style["right"] = '0';
      }
      return style;
    },
    pointValue(point) {
      return parseFloat(point.title.substring(0, point.title.length - 1));
    },
    pointPostfix(point) {
      return point.title.charAt(point.title.length - 1);
    },
  },
  computed: {
    video() {
      return get(this, "content.video.url", false);
    }
  },
};
</script>

<template>
  <intersect @enter="initAnimations()">
    <div
      class="ar-16by9 w-100 position-relative bg-contain-center"
      :style="{'background-image': getContentResponsiveImageURL('image')}"
      stripe-smartphone-desktop-component
    >
      <intersect @enter="toggleVideo(true)" @leave="toggleVideo(false)" v-if="video">
        <div class="position-absolute-center">
          <video loop muted webkit-playsinline playsinline ref="video" class="w-100 h-100">
            <source :src="video" type="video/mp4">
          </video>
        </div>
      </intersect>
      <div v-for="(point, index)  in content.points">
        <div class="point__dot-container d-flex align-items-center position-absolute" :class="{'flex-row-reverse': point.align == 'right'}" :style="dotContainerStyle(point)">
          <div class="point__line" :ref="`point-line-${index}`"></div>
          <div class="point__dot"></div>
        </div>
        <div
          class="position-absolute point bg-white"
          :style="pointStyle(point)"
          :key="`point-${contentId}-${index}`"
          :ref="`point-${contentId}-${index}`"
        >
          <div class="d-flex flex-column justify-content-center px-3">
            <div 
              v-if="point.icon" 
              class="point__icon mb-3" 
              :class="[`m${point.align == 'left' ? 'r' : point.align == 'right' ? 'l' : 'x'}-auto`]"
            >
              <div class="bg-contain-center ar-1by1 w-100" :style="{'background-image': getBackgroundUrl(point.icon.url)}"></div>
            </div>
            <div class="point__title" v-if="point.title">
              <span class="point__odometer-number"> </span>
              {{ pointPostfix(point) }}
            </div>
            <p class="point__description" v-if="point.description" v-html="point.description"></p>
          </div>
        </div>
      </div>
    </div>
  </intersect>
</template>

<style lang="scss">
[stripe-smartphone-desktop-component] {

  $animation-duration: var(--animation-duration);
  $text-dim: var(--text-dim);
  $dot-dim: var(--dot-dim);
  $line-height: 2px;

  video {
    object-fit: contain;
  }

  .point {
    max-width: $text-dim;
    max-height: $text-dim;
  }

  .point__icon {
    width: 3rem;
  }

  .point__title,
  .point__odometer-number,
  .odometer-value {
    font-weight: 700 !important;
    font-size: 3rem !important;
    font-family: $font-family-default !important;
    color: $dark;
  }

  .point__description {
    color: $dark;
  }

  .odometer-digit {
    width: 1.85rem;
    color: red;
  }

  .point__dot {
    width: $dot-dim;
    height: $dot-dim;
    background-color: $primary;
    border-radius: 100%;
    opacity: 0;
  }

  .point__line {
    background-color: $primary;
    height: $line-height;
  }

  .animated-line {
    animation-name: reach-point;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
  }

  .animated-dot {
    animation-name: bounce-in;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  $delays:("1": .5s, "2": 1s, "3": 1.5s, "4": 2s, "5": 2.5s, "6": 3s);
  @each $delay, $delay-val in $delays {
    .d-#{$delay} {
      animation-delay: $delay-val;
    }
  }
}

@keyframes reach-point {
  from { width: 0%; }
  to { width: 100%; }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(.9);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
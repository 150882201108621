<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { debounce, get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      top: true,
      fullscreen: false,
      absoluteBackground: false,
    }
  },
  methods: {
    getLangUrl(lang) {
      const content = this.pageModel.name_to_content[this.pageModel.main_content_name]
      return content ? `/${lang}${content.slug}` : `/${lang}`;
    },
    isItemActive(item) {
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    hiddenItem(item) {
      return !(item.url.url.split("$")[1] === "empty");
    },
    toggleFullScreen() {
      if (this.fullscreen) {
        clearAllBodyScrollLocks();
        this.fullscreen = false;
      } else {
        this.fullscreen = true;
        Vue.nextTick(() => {
          disableBodyScroll(this.$refs["fullscreen-menu"]);
        });
      }
    },
  },
  mounted() {
    Vue.nextTick(() => {
      window.addEventListener('scroll', debounce(() => {
        if (window.scrollY == 0) {
          this.top = true;
        } else {
          this.top = false;
        }
      }));
    });
  },
  computed: {
    sticky() {
      return this.pageModel.name_to_content[this.pageModel.main_content_name].sticky;
    },
    logoUrl() {
      const url = this.pageModel.seo.url || "";
      return url.indexOf("/fandom") > -1 && this.content.fandom_logo_url ? this.content.fandom_logo_url.url : "/";
    },
    logo() {
      const url = this.pageModel.seo.url || "";
      return url.indexOf("/fandom") > -1 ? this.content.fandom_logo : this.content.shado_logo;
    },
    secondaryLogo() {
      const url = this.pageModel.seo.url || "";
      return url.indexOf("/fandom") > -1 ? this.content.secondary_fandom_logo : this.content.secondary_logo;
    },
    jobsPage() {
      const url = this.pageModel.seo.url || ""
      return url.indexOf("/work-with-us") > -1 || url.indexOf("/lavora-con-noi") > -1;
    },
  },
};
</script>

<template>
  <div 
    id="main-navbar"
    class="w-100 align-items-center d-flex justify-content-between px-3"
    :class="{ 'bg-dark background-none': !top || sticky, 'sticky-top': sticky }"
    vue-header-component
  > 
    <transition leave-active-class="animated fadeOut faster">
      <div class="bg-dark position-absolute-center d-md-none" v-if="absoluteBackground"></div>
    </transition>
    <div class="header__logo" v-if="logo">
      <a :href="logoUrl">
        <h1 class="h-100 m-0 d-flex align-items-center">
          <img :src="logo.url">
        </h1>
      </a>
    </div>
    <div class="d-flex align-items-center">
      <nav class="navbar navbar-expand-lg">
        <div class="header__item container-fluid d-flex align-items-center" v-for="(item, index) in content.menu" :key="`menu-item-${index}`">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="main_nav">
            <ul class="navbar-nav">
              <li class="nav-item" :class="item.menu ? 'dropdown' : null">
                <a v-if="!item.menu"
                    class="nav-link text-uppercase text-decoration-none"
                    :class="[(item.menu ? 'dropdown-toggle' : null), {'font-weight-bold text-underline': isItemActive(item)}]"
                    :target="getTarget(item.url)"
                    :href="item.url.url"
                    :data-bs-toggle="item.menu ? 'dropdown-toggle' : null"
                >
                  {{ item.url.title }}
                </a>
                <a v-else 
                    class="nav-link text-uppercase text-decoration-none"
                    :class="[(item.menu ? 'dropdown-toggle' : null), {'font-weight-bold text-underline': isItemActive(item)}]"
                    href="javascript: void(0)"
                    :data-bs-toggle="item.menu ? 'dropdown-toggle' : null"
                >
                  {{ item.url.title }}
                </a>
                  <ul class="dropdown-menu bg-white" v-if="item.menu">
                    <li v-for="subItem in item.menu">
                      <a class="dropdown-item" :href="subItem.url.url" :target="getTarget(subItem.url)">
                        {{subItem.url.title}}
                      </a>
                    </li>
                  </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="d-flex align-items-center">
      <div class="header__secondary_logo mr-2" v-if="secondaryLogo">
        <a :href="content.secondary_logo_url ? content.secondary_logo_url.url : 'javascript: void(0)'" :target="content.secondary_logo_url ? getTarget(content.secondary_logo_url) : '_self'"><img :src="secondaryLogo.url"></a>
      </div>
      <div v-if="jobsPage && content.jobs_button">
        <a class="btn btn-primary header__btn-jobs d-none d-md-block mx-3" :href="content.jobs_button.url" :target="getTarget(content.jobs_button)">
          {{content.jobs_button.title}}
        </a>
      </div>
      <div class="mx-2 d-md-none">
        <div id="nav-icon1" :class="{'open': fullscreen}" @click="toggleFullScreen()">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <transition 
      enter-active-class="animated slideInDown faster delay-05s" 
      leave-active-class="animated slideOutUp faster"
      v-on:before-enter="absoluteBackground = true"
      v-on:after-leave="absoluteBackground = false"
    >
      <div class="header__fullscreen" v-if="fullscreen" ref="fullscreen-menu">
        <div class="ml-3 pl-3 my-3" v-for="(item, index) in content.menu" :key="`menu-item-${index}`">
          <div class="d-flex" v-if="!item.menu">
            <a 
                :href="item.url.url"
                :target="getTarget(item.url)"
                class="text-uppercase text-decoration-none text-white"
                :class="{'font-weight-bold': isItemActive(item)}"
              > 
              {{ item.url.title }}
            </a>
          </div>
          <div v-else class="d-flex">
            <a
              href="javascript: void(0)"
              data-toggle="dropdown"
              class="nav-item-mobile dropdown-toggle text-decoration-none bg-transparent py-0">
              {{ item.url.title }}
            </a>
            <ul class="dropdown-menu d-block" v-if="item.menu">
              <li v-for="subItem in item.menu" v-if="item.menu">
                <a class="dropdown-item" :href="subItem.url.url" :target="getTarget(subItem.url)">
                  {{subItem.url.title}}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="header__item ml-3 pl-3 my-2 align-items-center d-flex">
          <a :href="getLangUrl('en')" class="pr-2 lang-link" :class="{'font-weight-bold': globalState.lang === 'en'}">en</a>
          <div class="text-white">|</div>
          <a :href="getLangUrl('it')" class="pl-2 lang-link" :class="{'font-weight-bold': globalState.lang === 'it'}">it</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
[vue-header-component] {
  background-color: transparent;
  background: linear-gradient(0deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
  transition: background-color $animation-time-medium;
  z-index: $header-zindex;
  height: $header-height;
  position: fixed;
  top: 0;

  &.background-none {
    background: none;
  }

  .btn {
    background-color: none;
  }

  .btn:focus, .btn:focus {
    box-shadow: none;
  }

  .btn-drop{
    color:$white;
    padding:0px;

    &:focus{
      border:0px
    }
  }

  .text-underline {
    position: relative;

    &:before {
      content: "";
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      background-color: $white;
    }
  }

  .header__secondary_logo img {
    height: $logo-height;
  }

  .header__logo {
    height: $logo-height;
    z-index: 1;

    & img {
      max-height: 100%;
      width: auto;
    }
  }

  .header__item a {
    font-size: $header-items-font-size;
  }

  .header__fullscreen {
    height: calc(100vh - #{$header-height} + 1px);
    background-color: $dark;
    top: calc(#{$header-height} - 1px);
    overflow-y: auto;
    position: fixed;
    width: 100%;
    z-index: 1;
    left: 0;
  }

  .header__btn-jobs {
    z-index: 1;
    padding: .75rem 1.25rem;
  }

  .lang-link {
    color: $white;
    font-size: $header-items-font-size;
    text-transform: uppercase;
  }


  .nav-link {
    transition: opacity .25s ease-in-out;
    color: $white;
    padding-bottom: 0.01rem;

    &:hover{
      transition: opacity .25s ease-in-out;
      opacity: 0.7;
    }
  }


  @include media-breakpoint-up(md) {
    .navbar .nav-item .dropdown-menu{ display: none; }
    .navbar .nav-item:hover .nav-link{   }
    .navbar .nav-item:hover .dropdown-menu{ display: block; }
    .navbar .nav-item .dropdown-menu{ margin-top:0; }
    .dropdown-menu { 
      border: 0px;
      border-radius: 0px !important;
      padding-top:15px;
      padding-bottom: 15px;
    }
    .nav-item{
      transition: color .25s ease-in-out;
      color: $white;
      padding-top:40px;
      padding-bottom:40px;
    }
    .dropdown-item {
    transition: color .25s ease-in-out;
    color: $black;
    background-color: $white;
    font-weight: 400;
    line-height: 2rem;
    }
    .dropdown-item:hover {
      transition: color .25s ease-in-out;
      color:$light-grey;
    }
    .dropdown-toggle:hover{
      &::after{
        transition: transform .25s ease-in-out;
        transform: rotate(180deg);
      }
    }
  }

  @include media-breakpoint-down(md) {
    .navbar .nav-item .dropdown-menu{ 
      display: none; 
    }
    .dropdown-menu.show { 
      transition: display .25s ease-in-out;
      background-color: #1b1b1b;
      border: 0px;
      position:relative !important;
      top:20px !important;
      left: -6rem !important;
      padding-bottom: 20px;
      transform: none !important;
      line-height: 1.3rem;
    }

    .d-flex{
      .nav-item-mobile {
        transition: color .25s ease-in-out;
        color: $white;
      }
      .dropdown-toggle::after{
        color: $white;
      }
    }

    .d-flex.show {
      .nav-item-mobile {
        transition: color .25s ease-in-out;
        color: $light-grey;
      }
      .dropdown-toggle::after{
        color: $light-grey;
        transition: transform .25s ease-in-out;
        transform: rotate(180deg);
      }
    }

    .dropdown-item {
      color: $white;
      background-color: $dark;
      font-weight: 300;
    }
  }


  /* Haburger css */

  #nav-icon1 {
    z-index: 1;
    width: 1rem; 
    height: .75rem;
    position: relative;
    transform: rotate(0deg) .5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon1:hover span {
    background-color: $gray-400;
  }

  #nav-icon1 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $white;
    opacity: 1;
    left: 0;
    transition: all .25s ease-in-out;
    transform: rotate(0deg) .25s ease-in-out;
  }

  #nav-icon1 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon1 span:nth-child(2) {
    top: 5px;
  }

  #nav-icon1 span:nth-child(3) {
    top: 10px;
  }

  #nav-icon1.open span:nth-child(1) {
    top: 5px;
    transform: rotate(135deg);
  }

  #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -1rem;
  }

  #nav-icon1.open span:nth-child(3) {
    top: 5px;
    transform: rotate(-135deg);
  }
}

.sticky-top {
  position: sticky !important;
}
</style>


<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { Youtube } from 'src/modules/youtube_module.js'

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    toggleYoutubePopup: Youtube.toggleYoutubePopup,
    openYoutubePopupIfPresent(event) {
      event.preventDefault();
      this.toggleYoutubePopup();
    }
  },
  computed: {
  }
};
</script>

<template>
  <div :style="theme" class="themed-item" @click="openYoutubePopupIfPresent($event)" tile-case-component>
    <tile :content="content" :opt-container-content="containerContent"></tile>
  </div>
</template>



<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return { };
  },
  mounted() {
    if (this.content.stripes) {
      this.content.stripes.forEach((stripe) => {
        if (!Fandom.exists(stripe.width)) {
          Vue.set(stripe, "width", 12);
        }
      });
    }
  },
  computed: {
    headerHeightClass() {
      return Fandom.exists(this.content.background_image) ? "mh-50vh" : "mh-40vh";
    },
    headerColorClass() {
      return Fandom.exists(this.content.background_color) ? "bg-" + this.content.background_color : "bg-dark";
    },
    headerTextColorClass() {
      return Fandom.exists(this.content.text_color) ? "text-" + this.content.text_color : "text-white";
    },
    headerTextAlignClass() {
      return Fandom.exists(this.content.text_align) ? "text-" + this.content.text_align : "text-left";
    },
    category() {
      if (Fandom.exists(this.content.category)) {
        return this.content.category;
      } else {
        const decorator = Fandom.getDecorator(this.content);
        if (decorator) {
          return decorator.title;
        }
      }
    },
    headerTextClasses() {
      return [
        this.headerTextColorClass,
        this.headerTextAlignClass
      ]
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="bg-dark" :class="contentType" v-if="isContentVisibleToUser()" page-with-image-header-component>
    <div class="bg-cover-center text-center" :style="{'background-image': getContentResponsiveImageURL('background_image')}" :class="headerColorClass">
      <div class="container">
        <div class="row align-items-center justify-content-center" :class="headerHeightClass">
          <div class="col-md-10 py-4">
            <p class="page-header-catgegory" :class="headerTextColorClass" v-if="category">{{category}}</p>
            <component v-if="content.title" :is="content.title_tag || 'h2'" :class="[(content.title_class || 'h1'), headerTextClasses]">{{content.title}}</component>
            <component v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" :class="[(content.subtitle_class || 'h3'), headerTextClasses]">{{content.subtitle}}</component>
            <component v-if="content.description" :is="content.description_tag || 'div'" v-html="content.description" :class="headerTextClasses"></component>
            <div class="w-100 mt-3" :class="headerTextAlignClass" v-if="content.button">
              <a class="btn btn-outline-white" :href="content.button.url" :target="getTarget(content.button)">{{content.button.title}}</a>
            </div>
          </div>
          <div class="col-md-2 py-4" v-if="content.image">
            <div class="icon w-100 ml-md-auto">
              <img :src="content.image.url" class="w-100 h-100">
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
    <div class="container mb-4" v-if="content.button">
      <div class="row justify-content-center">
        <div class="col-md-12" :class="headerTextAlignClass">
          <a class="btn btn-primary" :href="content.button.url" :target="getTarget(content.button)">{{content.button.title}}</a>
        </div>
      </div>
    </div>
     <stripe-carousel :content="pageModel.name_to_content[content.carousel]" v-if="content.carousel" :opt-container-content="containerContent" :tileAspectRactioClass="'ar-1by1'"></stripe-carousel>
  </div>
</template>

<style lang="scss" scoped>
[page-with-image-header-component] {
  .mh-40vh {
    min-height: 40vh;
  }

  .icon {
    max-width: 7rem;
    max-height: 7rem;
  }
}
</style>
<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data() {
    return {
      show: false,
      rippleAnimation: false,
      rippleClasses: "",
      rippleCoord: {
        top: null,
        left: null
      }
    }
  },
  methods: {
    updatePinInteractionCustomized(event) {  
      this.rippleClasses = "";
      this.rippleCoord.top = `${event.offsetY - 40}px`;
      this.rippleCoord.left = `${event.offsetX - 40}px`;

      const successCallback = () => {
        Vue.nextTick(() => {
          this.rippleClasses = "ripple-animation success";
        })
      };

      const unauthorizedCallback = () => {
        Vue.nextTick(() => {
          this.rippleClasses = "ripple-animation success";
        })
      };

      this.updatePinInteraction(this.containerContent, this.content, this.content.interaction, null, successCallback, unauthorizedCallback);
    },
  },
  computed: {
    getPinnableAreaContainerLayout() {
      return {
        'top' : this.content.coord_y ? `${this.content.coord_y}%` : '50%',
        'left': this.content.coord_x ? `${this.content.coord_x}%` : '50%',
        'width' : this.content.width ? `${this.content.width}%` : '25%',
        'height' : this.content.height ? `${this.content.height}%` : '25%',
      }
    },
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="d-flex justify-content-center align-items-center" interaction-single-pin-component>
    <div class="pin-media-area">
      <div class="pin-container" :style="getPinnableAreaContainerLayout">
        <p class="feedback text-center" :class="{'feedback-animation': isInteractionFeedbackPhase(containerContent, content.interaction)}">{{content.text_feedback}}</p>
        <div @click="updatePinInteractionCustomized($event)" class="pin cursor-pointer w-100 h-100">
          <div class="ripple" :class="rippleClasses" :style="{'top': rippleCoord.top, 'left': rippleCoord.left}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[interaction-single-pin-component] {
  background-color: transparent !important; 
  
  .pin-container {
    position: absolute;
  }

  .pin-media-area {
    position: relative;
    width: 100vw;
    height: calc((9 / 16) * 100vw);
  }

  @media (min-aspect-ratio: 16/9) {
    .pin-media-area {
      height: 100vh;
      width: calc((16 / 9) * 100vh);
    }
  }

  .feedback {
    position: absolute;
    right: -2rem;
    left: -2rem;
    color: $white;
    font-size: 2rem;
    display: none;
    opacity: 0;
    text-shadow: 0 1px 0 $primary;

    &.feedback-animation {
      display: block;
      animation: pinMessageFadeInAndOut 2.5s linear, pinMessageMoveInAndOut 2.5s linear forwards;
    }
  }

  .ripple {
    position: absolute;
    width: 80px;
    height: 80px;
    transform: scale(0);
    border-radius: 50%;
    background: $primary;
    opacity: 1;

    &.error {
      background-color: $red;
    }

    &.success {
      background-color: $green;
    }

    &.ripple-animation {
      animation: rippleScale $animation-time-medium linear, rippleFadeIn $animation-time-medium linear;
    }
  }

  @keyframes rippleFadeIn {
    100% {
      transform: scale(2);
    }
  }

  @keyframes rippleScale {
    100% {
      opacity: 0;
    }
  }

  @keyframes pinMessageFadeInAndOut {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    80% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes pinMessageMoveInAndOut {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(-300%);
    }
  }
}
</style>
<script>
import { fandomTranslate } from "src/modules/common_module";

export default {
  props: {
    successMessage: String,
    success: Boolean,
    errors: Array
  },
  methods: {
    ft: fandomTranslate,
  },
  computed: {
    feedbackRequired() {
      return (!!this.errors && this.errors.length > 0) || !!this.success;
    }
  }
};
</script>

<template>
  <div form-feedback-component v-if="feedbackRequired" class="py-3" >
    <div class="text-success" v-if="success">{{successMessage}}</div>
    <div class="text-danger" v-if="errors && errors.length > 0">
      {{ft("globals.form.errors_compiling_module")}}
      <ul>
        <li class="text-danger" v-for="message in errors" v-html="message"></li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
  import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
  import { get } from "lodash";
  
  export default {
    mixins: [fandomBasicMixin, fandomExtendedMixin],
    mounted() {
    },
    methods: {
      getBackgroundUrl: Fandom.getBackgroundUrl,
    },
    computed: {
      paddingY() {
        const paddingY = get(this, ["content", "padding_y"], 4);
        return [
          `py-${paddingY}`
        ];
      },
      aspectRatioClasses() {
        return [(Fandom.exists(this.content.height) && this.content.height.indexOf("$") === 0) ? this.content.height.replace("$", "ar-") : "ar-16by9",];
      },
      contentClasses() {
        const classes = [];

        if (this.content.boxed) {
          classes.push("inset");
        }

        // Mobile reverse management. Default image and text
        if ((globalState.viewport.sm || globalState.viewport.xs) && this.content.reverse_mobile) {
          classes.push("flex-column-reverse");
        } else if (this.content.reverse) {
          classes.push("flex-row-reverse");
        }
        
        return classes;
      }
    }
  }
</script>
  
<template>
  <div v-easyadmin="easyadminId" :class="[contentType, paddingY]" :style="theme" class="themed-item" stripe-banner-component>
    <div class="container position-relative overflow-hidden h-100">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="row justify-content-center" :class="contentClasses">
            <div class="col-12 col-sm-6 media-column d-flex p-0">
              <div class="bg-contain-center w-100 position-relative" v-if="content.image" :title="content.image.alt" :style="{'background-image': getBackgroundUrl(content.image.url)}" :class="aspectRatioClasses"></div>
            </div>
            <div class="col-12 col-sm-6 d-flex flex-column justify-content-center">
              <text-section class="text-section" :content="content"></text-section>
            </div>
          </div>
        </div>    
        </div>
      </div>
  </div>
</template>
  
<style lang="scss">
[stripe-banner-component] {


  .btn {
    font-size: 0.85rem;
    padding: .5rem 1rem;
  }

  .text-section {
    margin: 0 !important;
   } 

  @include media-breakpoint-down(sm) {
    .text-section {
      text-align: center !important;
      margin-top: 0 !important;
    }
    .bg-contain-center {
      background-size: 80%;
    }
  }

  @include media-breakpoint-up(sm) {
    .bg-contain-center {
      background-size: contain;
    }
  }

  @include media-breakpoint-up(sm) {
    .text-section {
      text-align: left !important;
    }
  }
}
</style>
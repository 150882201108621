<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { throttle } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return { 
      isVisible: false,
      scrollFromTop: this.content.scroll_from_top ? this.content.scroll_from_top : 600
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.calcIsVisible();
      window.addEventListener("scroll", this.calcIsVisible);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.calcIsVisible);
  },
  watch: {
    isVisible() {
      Vue.nextTick(() => {
        if (this.isVisible) {
          this.enterHandler();
        } else {
          this.leaveHander();
        }
      });
    }
  },
  methods: {
    calcIsVisible: throttle(function() {
      const isScrollable = document.body.scrollHeight > window.innerHeight;
      if (isScrollable) {
        Vue.set(this, "isVisible", document.documentElement.scrollTop > this.scrollFromTop);
      } else {
        Vue.set(this, "isVisible", true);
      }
    }, 500),
    enterHandler() {
      const bannerHeight = $(this.$el).innerHeight();
      $("body").css("padding-bottom", `${bannerHeight}px`);
    },
    leaveHander() {
      // Warning: default body padding bottom must be 0 for the code to be correct. 
      // Otherwise it is necessary to restore the previous one.
      $("body").css("padding-bottom", `0px`);
    }
  }
};
</script>

<template>
  <transition enter-active-class="animated faster slideInUp" leave-active-class="animated faster slideOutDown">
    <div v-easyadmin="easyadminId" :style="theme" v-if="isContentVisibleToUser() && isVisible" :class="contentType" stripe-button-component>
      <div class="container d-flex align-items-center justify-content-between py-3">
        <component
          v-if="content.title"
          class="mb-0 pr-4"
          :class="content.title_class || 'banner-title'"
          :is="content.title_tag || 'div'"
          v-html="content.title"
        />
        <a 
          v-if="content.button" 
          class="btn btn-primary" 
          :href="content.button.url"
          :target="getTarget(content.button)" 
        >
          {{content.button.title}}
        </a>
      </div>
    </div>
  </transition>
</template>


<style lang="scss">
[stripe-button-component] {
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: $zindex-fixed;

  color: var(--theme-color) !important;
  background-color: var(--theme-background) !important;

  .banner-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  @include media-breakpoint-down(sm) {
  }
}
</style>
<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    keypointImage(logo) {
      return !!logo.logo ? `url(${logo.logo.url})` : '';
    },
  },
  computed: {
    colClass() {
      return this.content.width ? `col-${this.content.width}` : "col-12";
    },
    keypointWidth() {
      return !!this.content.column_count ? `${100/this.content.column_count}%` : '16.6666%';
    },
    arClass()  {
      return !!this.content.aspect_ratio ? this.content.aspect_ratio : 'ar-16by9';
    }
  }
}
</script>

<template>
  <div
    v-easyadmin="easyadminId"
    :id="easyadminId"
    :class="[contentType]"
    :style="theme"
    class="themed-item"
    list-keypoint-logo-component
  >
    <div class="container py-3">
      <div class="row justify-content-center">
        <div :class="colClass" class="text-center">
          <p class="h2 font-weight-light mb-5" v-if="content.title">{{ content.title }}</p>
          <div class="w-100 d-flex flex-wrap">
            <div class="logo" v-for="(logo, index) in content.keypoint" :key="`logo-${index}`" :style="{'width': keypointWidth}">
              <div class="bg-contain-center" :style="{'background-image': keypointImage(logo)}" :class="arClass">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[list-keypoint-logo-component] {
  
  @include media-breakpoint-down(md) {
    .logo {
      width: 25% !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .logo {
      width: 33.3333% !important;
    }
  }

}
</style>
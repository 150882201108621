<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  methods: {
    updateInteraction(answer) {
      const successBlock = () => {
        window.location.href = answer.link.url;
      }
      this.updateTriviaInteraction(this.containerContent, this.content, this.content.interaction, answer.$id, null, successBlock)
    }
  },
  computed: {
    getUserAvatar() {
      return get(this.pageModel, ["user", "avatar", "url"], false);
    },
    getUserBadgeStyle() {
      let styles = {};
      if (this.getUserAvatar) {
        styles['background-image'] = `url(${this.getUserAvatar})`;
        styles['background-color'] = "transparent";
      }
      return styles;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="bg-cover-center" :class="contentType" v-if="isContentVisibleToUser()" :style="{'background-image': getContentResponsiveImageURL('background_image'), 'background-color': content.background_color}" trivia-component>
    <div class="container">
      <div class="row align-items-center justify-content-center mh-100vh py-4">
        <div class="col-md-8 text-center">
          <h2 class="pb-4 mb-0" :style="{'color': content.text_color}">{{content.question}}</h2>
          <div class="row">
            <div class="col-md-6 px-5 py-3 p-sm-3" v-for="answer in content.answers">
              <button  @click="updateInteraction(answer)" class="btn btn-trivia-answer w-100 position-relative" >
                {{answer.text}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[trivia-component] {
  background-color: $trivia-component-bg-color;
  color: $trivia-component-color;
  overflow: scroll;
  max-height: 100vh;

  .small-avatar {
    width: 2rem;
    height: 2rem;
    top: -1rem;
    left: -1rem;
    border-radius: 50%;
    position: absolute;
    background-color: #{$user-badge-bg-color};

    .far {
      font-size: 1.5rem;
      color: #{$user-badge-color};
    }
  }

  .btn-trivia-answer {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 5rem;
    font-size: 0.85rem;

    &.btn-danger {
      background-color: $danger;
      border-color: $danger;
    }

    &.btn-success {
      background-color: $success;
      border-color: $success;
    }
  }
}
</style>

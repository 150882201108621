<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    expandUrl: Fandom.expandUrl
  },
  data() {
    return {
      paddingYDefault: 4
    };
  },
  computed: {
    columnClasses() {
      const columnWidth = Fandom.exists(this.content.width) ? this.content.width : Fandom.retrieveFromGlobalState("one_col_text_width", 10);
      return [
        `col-12 col-md-${columnWidth}`,
        `text-${this.content.text_align}`
      ];
    },
    containerClasses() {
      const classes = [];

      if (this.content.background_fixed && !(globalState.viewport.sm || globalState.viewport.xs || globalState.viewport.md)) {
        classes.push("bg-fixed");
      } else {
        classes.push("bg-cover-center");
      }

      return classes;
    },
  },
};

</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentType, paddingY]" v-if="isContentVisibleToUser()" :style="theme" class="themed-item" one-col-component>
    <span v-if="content.stripe_id" class="anchor" :id="content.stripe_id"></span>
    <div class="container d-flex flex-column justify-content-center position-relative overflow-hidden" :style="{'min-height': content.height}" :class="containerClasses">
      <div class="position-absolute-center bg-cover-center w-100 h-100" :style="{'background-image': getContentResponsiveImageURL('background_image')}"></div>
      <div class="row text-align-center justify-content-center">
        <div :class="columnClasses">
          <text-section :content="content" :opt-container-content="containerContent"></text-section>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { shadoEditorialMixin } from '../../modules/shado_mixins_module';

export default {
  mixins: [fandomBasicMixin, shadoEditorialMixin],
  data() {
    return {
      defaultTextAlignment: "center",
    }
  },
  mounted() {
    Vue.nextTick(() => {
      $(this.$el).on('hidden.bs.modal', function (e) {
        Vue.delete(globalState, "tilePopUpInfo");
      });
      $(this.$el).modal("show");
    });
  },
  methods: {
    closeModal() {
      $(this.$el).modal("hide");
    }
  },
  computed: {
    badges() {
      const result = [];
      const decorators = this.containerContent.decorators || null;
      if (decorators) {
        Object.keys(decorators).forEach(decoratorCategory => {
          const badge = this.pageModel.decorators[decoratorCategory][decorators[decoratorCategory]];
          if (badge) {
            result.push(badge);
          }
        });
      }
      return result;
    }
  },
};
</script>

<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true" pop-up-component>
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <component
            :is="content.layout"
            :animation="false"
            :content="content"
            :opt-container-content="containerContent"
            :badges="badges"
            class="h-100 pop-up__content white-theme d-flex align-items-center"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  computed: {
    columnClass() {
      return this.content.column_count ? `col-md-${12/this.content.column_count}` : "col-md-3";
    },
    widthClass() {
      return this.content.width ? `col-lg-${this.content.width}` : "col-lg-12";
    },
    themeClass() {
      return this.content.theme ? `${this.content.theme}-theme` : "";
    },
    textPaddingClass() {
      const paddingMap = {
        'center': 'px-4',
        'left': 'pr-4',
        'right': 'pl-4'
      }

      return this.content.text_align ? paddingMap[this.content.text_align] : 'px-4';
    }
  },
  methods: { 
    getWrapperTag(point) {
      return Fandom.exists(point.url) ? "a" : "div";
    },
    getPointUrl(point) {
      return Fandom.exists(point.url) ? point.url.url : null;
    },
    getPointTitle(point) {
      return get(point, ["url", "title"], null); 
    },
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getTarget: Fandom.getTarget,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="py-4 themed-item" :class="[contentType, themeClass]" v-if="isContentVisibleToUser()" list-keypoint-component :style="theme"">
    <stripe-header :content="content" optTextAlign="center"></stripe-header>
    <div class="container">
      <div class="row justify-content-center">
        <div :class="widthClass">
          <div class="row justify-content-center">
            <component 
              v-for="(point, index) in content.keypoint" 
              :key="index" 
              class="col-12 px-4 my-4" 
              :is="getWrapperTag(point)" 
              :href="getPointUrl(point)" 
              :title="getPointTitle(point)" 
              :target="getTarget(point.url)" 
              :class="[columnClass, {'has-url': point.url}, `text-${content.text_align ? content.text_align : 'center'}`]"
            >
              <div 
                v-if="point.image" 
                class="point-image my-4" 
                :class="[`m${content.text_align == 'left' ? 'r' : content.text_align == 'right' ? 'l' : 'x'}-auto`, {'big': content.big}]"
              >
                <div class="bg-contain-center ar-1by1 w-100" :style="{'background-image': getBackgroundUrl(point.image.url)}"></div>
              </div>
              <div :class="textPaddingClass">
                <h3 class="mb-2 font-weight-bold" v-if="point.title">{{point.title}}</h3>
                <p v-if="point.description" v-html="point.description"></p>
                <div v-if="point.button">
                  <a class="btn shadow-none my-3" :class="getPrimaryButtonClass(point.button_class)" :href="point.button.url" :target="getTarget(point.button)">
                    <i :class="point.button_icon" class="mx-2" v-if="point.button_icon"></i>
                    {{point.button.title}}
                  </a>
                </div>
              </div>
            </component> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[list-keypoint-component] {

  .point-image {
    width: 4rem;
  }

  .point-image.big {
    width: 10rem;
  }

}

</style>
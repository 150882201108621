const shadoEditorialMixin = {
  props: ["width"],
  data() {
    return {
      defaultTextAlignment: "left",
      defaultWidth: 12,
    }
  },
  computed: {
    flexAlignmentClass() {
      const align = this.content.text_align || this.defaultTextAlignment;
      const map = {'left': 'start', 'center': 'center', 'right': 'end'};
      return `justify-content-${map[align]}`;
    },
    columnClass() {
      const width = !!this.content.width ? this.content.width : this.width ? this.width : this.defaultWidth;
      return `col-lg-${width}`;
    },
  }
}

export {
  shadoEditorialMixin
};

<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import {InteractionWidgetWrapper} from 'interaction';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  components: {InteractionWidgetWrapper},
  methods: {
    getAnswerClasses(answer) {
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        if (this.isAnswerCorrect(this.containerContent, this.content.interaction, answer.$id)) {
          return "correct";
        } else {
          const userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
          const userInteractionAnswerId = Fandom.exists(userInteraction.answer_id) ? userInteraction.answer_id : parseInt(userInteraction.data.answer_id);
          return userInteractionAnswerId == answer.$id ? "wrong" : this.themedButton;
        }
      } else {
        return this.themedButton;
      }
    },
    isUserAnswer(answerId) {
      return this.getUserInteractionAnswerId(this.containerContent, this.content.interaction) == answerId;
    }
  }
};
</script>

<template>
  <interaction-widget-wrapper :content="content" :opt-container-content="containerContent" trivia-widget-component>
    <p class="h4 mb-3">{{content.question}}</p>
    <div class="row justify-content-center">
      <div class="col-12">
        <div
          class="answer w-100 my-2 py-3 d-flex justify-content-between align-items-center"
          :class="getAnswerClasses(answer)"
          v-for="answer in content.answers"
          :key="`answer-${answer.$id}`"
          @click="updateTriviaInteraction(containerContent, content, content.interaction, answer.$id)"
        >
          <div class="px-3 flex-grow-1 answer__text">{{answer.text}}</div>
          <div v-if="isUserAnswer(answer.$id)" class="avatar themed-item-reverse">
            <i class="fal fa-user"></i>
          </div>
        </div>
      </div>
    </div>
  </interaction-widget-wrapper>
</template>

<style lang="scss">
[trivia-widget-component] {
  .answer {
    transition: all $animation-time-fast linear;
    border-radius: 0.5rem;
    position: relative;
    //overflow: hidden;
    background-color: var(--theme-background);
    cursor: pointer;

    &:hover {
      background-color: var(--theme-color);
      color: var(--theme-background);
    }

    &.correct {
      border: 2px solid $success;
    }

    &.wrong {
      border: 2px solid $danger;
    }
  }

  .avatar {
    width: 2rem;
    height: 2rem;
    top: -.5rem;
    left: -.5rem;
    border-radius: 50%;
    position: absolute;

    .fal {
      line-height: 2rem;
      font-size: 1rem;
    }
  }
}
</style>
<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { shadoEditorialMixin } from '../../modules/shado_mixins_module'
import { Youtube } from 'src/modules/youtube_module.js'
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, shadoEditorialMixin],
  data: function() {
    return {
      globalState: globalState,
    };
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    toggleYoutubePopup: Youtube.toggleYoutubePopup,
    scrollToBottom: Fandom.scrollToBottom,
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
  },
  computed: {
    hasGradient(){
      return !get(this.content, ["hide_gradient"], false);
    },
    isYoutubePresent() {
      const mediaType = get(this.content, ["play", "media_type"], false);
      return mediaType == "youtube";
    },
    alignClass() {
      return this.content.text_align ? `text-${this.content.text_align}` : '';
    }
  }
};
</script>

<template>
  <div
    v-easyadmin="easyadminId"
    :class="contentType"
    v-if="isContentVisibleToUser()"
    :id="`media-stripe-${containerContent.name}-${content.$id}`"
    video-content-component
  >
    <div 
      class="w-100 h-85vh bg-cover-center position-relative d-flex"
      :class="content.alternative ? 'align-items-end' : 'align-items-center'"
      :style="{'background-image': getContentResponsiveImageURL('image')}"
    >
      <div v-if="content.background_color" :style="{'background-color': content.background_color}" class="position-absolute-center"></div>
      <div v-if="content.video" class="position-absolute-center">
        <video 
          autoplay 
          loop 
          webkit-playsinline 
          playsinline 
          muted 
          class="w-100 h-100" 
          :id="`${content.name}-video`"
        >
          <source :src="getContentResponsiveImage('video')" type="video/mp4">
        </video>
      </div>
      <div :class="{'media-col__layer': hasGradient}" class="position-absolute-center"></div>
      <div class="container">
        <div class="row" :class="flexAlignmentClass" v-if="!content.alternative">
          <div :class="[columnClass, alignClass]" class="col-12 col-md-6">
            <text-section :content="content" :opt-container-content="containerContent" titleClass="h1"></text-section>
            <button class="btn btn-outline-white mt-3" @click="toggleYoutubePopup()" v-if="isYoutubePresent">
              {{ ft("globals.watch_video") }}
            </button>
          </div>
          <div class="w-100 d-flex justify-content-center text-white pb-5 position-absolute bottom-0 left-0" v-if="!content.hide_arrow">
            <span
              class="cursor-pointer w-100 d-inline-block text-center text-white"
              @click="scrollToBottom(`media-stripe-${containerContent.name}-${content.$id}`, 80)"
            ><i class="fal fa-chevron-down animated infinite pulse"></i></span>
          </div>
        </div>
        <div class="row justify-content-center" v-else>
          <div :class="columnClass" class="col-12 col-md-6 text-center text-white pb-5">
            <text-section :content="content" :opt-container-content="containerContent" titleClass="h1"></text-section>
            <button class="btn btn-outline-white my-3" @click="toggleYoutubePopup()" v-if="isYoutubePresent">
              {{ ft("globals.watch_video") }}
            </button>
            <span class="cursor-pointer w-100 d-inline-block text-center text-white" @click="scrollToBottom(`media-stripe-${containerContent.name}-${content.$id}`, 80)">
              <i class="fal fa-chevron-down animated infinite pulse"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[video-content-component] {

  .fal.fa-chevron-down {
    font-size: 2rem;
  }

  video {
    object-fit: cover;
  }

  .bottom-0 {
    bottom: 0;
  }

  .left-0 {
    left: 0;
  }

  .media-col__layer {
    background: linear-gradient(to bottom, rgba(27, 27, 27, 0), #1b1b1b);
  }
}
</style>
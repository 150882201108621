<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return { };
  },
  computed: {
    headerHeightClass() {
      return Fandom.exists(this.content.background_image) ? "mh-50vh" : "mh-40vh";
    },
    headerColorClass() {
      return Fandom.exists(this.content.background_color) ? "bg-" + this.content.background_color : "bg-dark";
    },
    headerTextColorClass() {
      return Fandom.exists(this.content.text_color) ? "text-" + this.content.text_color : "text-white";
    },
    category() {
      if (Fandom.exists(this.content.category)) {
        return this.content.category;
      } else {
        const decorator = Fandom.getDecorator(this.content);
        if (decorator) {
          return decorator.title;
        }
      }
    },
    themeClass() {
      return this.content.theme ? `${this.content.theme}-theme` : 'dark-theme';
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentType, themeClass]" v-if="isContentVisibleToUser()" page-with-image-header-component>
  	<div class="text-white bg-cover-center text-center" :style="{'background-image': getContentResponsiveImageURL('background_image')}" :class="headerColorClass">
  		<div class="container">
	  		<div class="row align-items-center justify-content-center" :class="headerHeightClass">
          <div class="col-lg-10 py-4">
            <p class="page-header-catgegory" :class="headerTextColorClass" v-if="category">{{category}}</p>
            <component v-if="content.title" :is="content.title_tag || 'h2'" :class="[(content.title_class || 'h2'), headerTextColorClass]">{{content.title}}</component>
            <component v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" :class="[(content.subtitle_class || 'h3'), headerTextColorClass]">{{content.subtitle}}</component>
            <component v-if="content.description" :is="content.description_tag || 'div'" v-html="content.description" :class="headerTextColorClass"></component>
          </div>
		    </div>
	  	</div>
  	</div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss" scoped>
[page-with-image-header-component] {
  .mh-40vh {
    min-height: 40vh;
  }
}
</style>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
 
export default {
  mixins: [fandomBasicMixin],
  computed: {
    isViewportSmall() {
      return globalState.viewport.sm || globalState.viewport.xs;
    },
  },
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    v-if="isContentVisibleToUser()" 
    :class="contentType"
    class="py-5 bg-white" 
    stripe-smartphone-component
  >
    <stripe-header :content="content"></stripe-header>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <stripe-smartphone-desktop :content="content" v-if="!isViewportSmall"></stripe-smartphone-desktop>
          <stripe-smartphone-mobile :content="content" v-if="isViewportSmall"></stripe-smartphone-mobile>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return {
    };
  },
  methods: {
  }
};
</script>

<template>
  <div :id="anchorId" v-easyadmin="easyadminId" class="bg-cover-center py-5 themed-item" :style="theme" simple-form-component>
    <div class="container">
      <stripe-header :content="content" class="pb-5"></stripe-header>
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-7 col-lg-6">
          <generic-form :content="content" :opt-container-content="containerContent"></generic-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>

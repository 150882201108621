<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Intersect from 'vue-intersect';
import { get } from "lodash";
 
export default {
  mixins: [fandomBasicMixin],
  components: { Intersect },
  data() {
    return {
      activePoint: null,
    }
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    toggleVideo(status) {
      const video = $(this.$refs.video)[0];
      if (status) {
        video.muted = true;
        video.play();
      } else {
        video.pause();
      }
    },
    pointStyle(point) {
      const style = {
        'top': `${point.coord_y_mobile}%`,
        'left': `${point.coord_x_mobile}%`
      };
      return style;
    },
    openModal(point) {
      this.activePoint = point;
      disableBodyScroll();
    },
    closeModal() {
      this.activePoint = null;
      clearAllBodyScrollLocks();
    }
  },
  computed: {
    video() {
      return get(this, "content.video.url", false);
    }
  },
};
</script>

<template>
  <div stripe-smartphone-mobile-component>
    <div
      class="ar-9by16 w-100 position-relative bg-contain-center"
      :style="{'background-image': getContentResponsiveImageURL('image_mobile')}"
      stripe-smartphone-mobile-component
    >
      <intersect @enter="toggleVideo(true)" @leave="toggleVideo(false)" v-if="video">
        <div class="position-absolute-center">
          <video loop muted webkit-playsinline playsinline ref="video" class="w-100 h-100">
            <source :src="video" type="video/mp4">
          </video>
        </div>
      </intersect>
      <div v-for="(point, index) in content.points">
        <div class="point d-flex justify-content-center align-items-center cursor-pointer" :style="pointStyle(point)" @click="openModal(point)">
          <i class="far fa-plus"></i>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div v-if="activePoint" class="position-fixed w-100 h-100 point-modal__layer"></div>
    </transition>
    <transition enter-active-class="animated faster fadeInUp" leave-active-class="animated faster fadeOutDown">
      <div v-if="activePoint" class="position-fixed w-100 h-100 point-modal d-flex justify-content-center align-items-center">
        <div class="point-modal__body bg-white mx-4 px-4 py-5 my-4 position-relative">
          <div class="close" @click="closeModal()">
            <i class="fal fa-times-circle cursor-pointer"></i>
          </div>
          <div v-if="activePoint.icon" class="point-modal__icon mb-3">
            <div class="bg-contain-center ar-1by1 w-100" :style="{'background-image': getBackgroundUrl(activePoint.icon.url)}"></div>
          </div>
          <div class="point-modal__title" v-if="activePoint.title">
            {{ activePoint.title }}
          </div>
          <p class="point-modal__description" v-if="activePoint.description" v-html="activePoint.description"></p>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
[stripe-smartphone-mobile-component] {

  .point {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    border-radius: 100%;
    background-color: $primary;
    color: $white;
    font-size: 1.5rem;
    z-index: 1;
  }

  video {
    object-fit: cover;
  }

  .point::before {
    content: '';
    -webkit-border-radius: 100%;
    height: 2.5rem;
    width: 2.5rem;
    position: absolute;
    z-index: -1;
    -webkit-animation: pulsate 3s cubic-bezier(0.2,1,0.2,1) infinite;
    opacity: 0.0;
  }

  @-webkit-keyframes pulsate{
    from{
      opacity:1;
      background-color: $primary;
      transform:scale(1)
    }
    to{
      opacity:0;
      background-color: $light-grey;
      transform:scale(3)
    }
  }

  .point-modal__layer {
    top: 0;
    left: 0;
    z-index: $header-zindex + 1;
    background-color: rgba($slate-grey, .75);
  }

  .point-modal {
    top: 0;
    left: 0;
    z-index: $header-zindex + 1;

    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      line-height: 0px;
      z-index: 1;
    }

    .point-modal__body {
      height: fit-content;
    }

    .point-modal__icon {
      width: 4.5rem;
    }

    .point-modal__title {
      font-weight: 700 !important;
      font-size: 4.5rem !important;
    }

    .point-modal__description {
      font-size: 1.25rem;
    }
  }
}
</style>
<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'

export default {
  mixins: [fandomBasicMixin]
}
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" shared-content-component>
    <component v-if="content.content" :is="getContent(content.content).layout" :content="getContent(content.content)" :key="getContent(content.content).name"></component>
  </div>
</template>

<style lang="scss">
</style>

<script>
import { fandomBasicMixin, fandomExtendedMixin, fandomTileMixin } from "src/modules/fandom_mixins_module.js";
import { mqLess } from 'src/modules/viewport_module';
import { get } from 'lodash';
import Intersect from 'vue-intersect';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, fandomTileMixin],
  components: { Intersect },
  data() {
    return {
      loadVideo: false
    }
  },
  mounted() {
  },
  methods: {
    mayAnchorTo(event) {
      const url = get(this.content, ['url','url'], Fandom.getContentURL(this.content));
      if (url.startsWith("#")) {
          let offset = $("#main-navbar").outerHeight();
          if (this.content.sticky) {
            offset += $(this.$el).outerHeight();
          }

          const position = $(url).offset().top - offset;
          $("html, body").animate({ scrollTop: position }, 0, "linear");

         event.preventDefault();
         return false;
      } else {
        return true;
      }
    },
    startVideo(event) {
      if (this.content.background_video && this.toggleVideoCondition(event)) {
        this.loadVideo = true;

        // We await the reprint so that the video is presented on the page
        Vue.nextTick(() => { 
          const video = $(this.$refs.video)[0];
          video.play();
        });
      }
    },
    pauseVideo(event) {
      if (this.content.background_video && this.loadVideo && this.toggleVideoCondition(event)) {
        const video = $(this.$refs.video)[0];
        video.pause();
        video.currentTime = 0;

        this.loadVideo = false;
      }
    },
    toggleVideoCondition(event) {
      return (event == 'mouseenter' && !mqLess("sm")) ||
             (event == 'intersect' && mqLess("sm"))
    }
  },
  computed: {
    url() {
      const url = get(this.content, ['url','url'], Fandom.getContentURL(this.content));
      return url && url.startsWith("#") ? "javascript: void(0)" : url;
    }
  }
}
</script>

<template>
  <intersect @enter="startVideo('intersect')" @leave="pauseVideo('intersect')">
    <div class="position-absolute-center" 
      v-easyadmin="easyadminId" 
      :class="contentType"
      @mouseenter="startVideo('mouseenter')"
      @mouseleave="pauseVideo('mouseenter')"
    >
      <a
        class="deco-none position-absolute-center cursor-pointer bg-transition"
        :target="getTarget(content.url)"
        :href="url" 
        @click="mayAnchorTo($event)"
        :style="theme"
        tile-component
      >
        <div class="position-absolute-center bg-cover-center bg" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
        <div class="position-absolute-center" v-if="loadVideo && content.background_video">
          <video ref="video" loop muted playsinline webkit-playsinline class="w-100 h-100">
            <source :src="content.background_video.url" type="video/mp4">
          </video>
        </div>
        
        <div v-if="!content.hide_gradient" class="position-absolute-center linear-gradient-y"></div>

        <div class="position-absolute-center p-4 d-flex flex-column justify-content-between">
          <div class="tile__icon">
            <img  class="img-fluid" v-if="content.icon" :src="content.icon.url"/>
          </div>
          <div class="tile__text-section">
            <p v-if="miniformat && miniformat.title" :miniformat="miniformat" class="my-2"><small>{{miniformat.title}}</small></p>
            <component v-if="content.title" :class="content.title_class" :is="content.title_tag || 'h4'" class="mb-0" :style="{'color': content.text_color}">
              {{content.title}}
            </component>
          </div>
        </div>
      </a>
    </div>
  </intersect>
</template>

<style lang="scss" scoped>
[tile-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  transition: all .25s;

  video {
    object-fit: cover;
  }

  &:not([href]):hover {
    color: var(--theme-color);
  }

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }
}
</style>



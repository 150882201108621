<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { Youtube } from 'src/modules/youtube_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  mounted() {},
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    toggleYoutubePopup: Youtube.toggleYoutubePopup,
  },
  computed: {
    date() {
      const date = new Date(this.content.activated_at);
      return date.toLocaleDateString(globalState.lang, { year: "numeric", month: "long", day: "numeric" });
    },
    isYoutubePresent() { 
      return this.playInteraction && "youtube" == this.playInteraction.media_type;
    },
    playInteraction() {
      return this.content.play;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentType]" :style="theme" class="py-5 themed-item" v-if="isContentVisibleToUser()" page-article-component>
    <div class="container my-4">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="row">
            <div v-if="decorator" class="col-12 pb-3">
              <p class="font-decorator text-uppercase">{{decorator.title}}</p>
            </div>
            <div v-if="content.title" class="col-12 pb-3">
              <h1>{{content.title}}</h1>
            </div>
            <div v-if="content.subtitle" class="col-12 pb-3">
              <p class="subtitle-text">{{content.subtitle}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-4" v-if="isYoutubePresent">
        <div class="col-12">
          <div @click="toggleYoutubePopup()" :class="{'cursor-pointer': isYoutubePresent}" class="my-2">
            <div class="position-relative">
              <div class="ar-16by9">
                <div class="position-absolute-center d-flex w-100 align-items-center justify-content-center h-100 bg-cover-center" :style="{'background-image': getBackgroundUrl(playInteraction.image.url)}">
                  <div class="video-content-play-icon d-flex align-items-center justify-content-center">
                    <i class="fas fa-play"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center my-4">
        <div v-if="content.description" class="col-lg-6">
          <div v-html="content.description"></div>
        </div>
      </div>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent">
    </component>
  </div>
</template>

<style lang="scss" scoped>
[page-article-component] {

  .video-content-play-icon {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.45);

    i {
      font-size: 1.5rem;
      color: $white;
      margin-left: 3px; //workaround to perfectly center play icon
    }
  }

  .font-decorator {
    font-size: 1.25rem;
  }

  .subtitle-text {
    font-size: 1.375rem;
  }
  
}
</style>

<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get }  from 'lodash';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
    }
  },
  mounted() { },
  methods: {
    badgeColor(name) {
      return `text-${get(this.pageModel, ["decorators"]) || 'white'}`;
    },
    getBadge(badge, key) {
      const defaultBadge = {title: badge, color: "white"};
      return get(this.pageModel, ["decorators", key, badge], defaultBadge);
    },
    openModal() {
      Vue.set(this.globalState, "tilePopUpInfo", {
        content: this.content.popup_content,
        containerContent: this.containerContent
      });
    }
  },
  computed: {
    badges() {
      return this.content.decorators || [];
    }
  }
};
</script>

<template>
  <div :style="theme" class="themed-item" @click="openModal" tile-popup-component>
    <tile :content="content" :opt-container-content="containerContent"></tile>
    <div class="tile-popup__badges">
      <div
        class="badge bg-dark mr-2 mb-2 text-uppercase font-weight-bold"
        :class="`text-${getBadge(badge, key).color}`"
        v-for="(badge, key) in badges"
        :key="`badge-${contentId}-${key}`"
      > {{getBadge(badge, key).title}} </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[tile-popup-component] {

  .tile-popup__badges {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  .h1,.h2 {
    color: $white;
  }

  video {
    object-fit: cover;
  }
  
}
</style>



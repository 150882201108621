<script>
import { interactionMixin } from 'src/modules/interactions_module.js';
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import Intersect from 'vue-intersect';

export default {
  component: { Intersect },
  mixins: [interactionMixin, fandomBasicMixin, fandomExtendedMixin],
  props: {
    showRestartBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contentResult: null,
      completed: false,
      isLoading: false
    };
  },
  methods: {
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    updateInteractionSuccessCallback(data, reset=false) {
      this.contentResult = data.content_result;
      
      if (reset) {
        this.completed = false;
        this.resize();
        this.$emit("interaction-handler", { action: "reset" });

        let contentUserInfo = this.getContentUserInfo(this.containerContent);
        Vue.set(contentUserInfo.id_to_interaction[this.content.interaction.id], "user_interaction", null);
      } else if (this.isLoading) {
        setTimeout(() => {
          this.completed = true;
          this.isLoading = false;
          this.resize();
        }, 3000);
      } else {
        this.completed = true;
      }

      this.resize();
    },
    handleError(data) {
      if (data.error) {
        setTimeout(() => {
          this.isLoading = false;
          this.completed = false;
          this.resize();
        }, 3000);
      }
    },
    resize() {
      Vue.nextTick(() => window.dispatchEvent(new Event('resize')));
    },
    checkResult() {
      if (this.contentResult && this.completed) {
        return;
      }
      this.isLoading = true;
      this.updateTestInteraction(this.containerContent, this.content, this.content.interaction, null, this.updateInteractionSuccessCallback, this.handleError);
    }
  },
  computed: {
    loading() {
      return this.content.loading;
    }
  }
};
</script>

<template>
  <intersect @enter="checkResult">
    <div v-easyadmin="easyadminId" :class="[contentType]" v-if="isContentVisibleToUser()" test-interaction-component>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" v-if="contentResult && !isLoading">
        <div v-if="completed" class="justify-content-center d-flex align-items-center flex-column h-100">
          <component :is="contentResult.layout" :content="contentResult" :opt-container-content="containerContent"></component>
          <a
            class="btn btn-primary my-3"
            href="javascript: void(0)"
            @click.prevent="resetTestInteraction(containerContent, content, content.interaction, null, updateInteractionSuccessCallback)"
          >
            {{ft("globals.test_interaction.redo")}}
          </a>
        </div>
      </transition>
      <div class="row loading-container justify-content-center align-items-center flex-column text-center" v-else>
        <div v-if="isLoading" class="col-12">
          <component v-if="loading.title" :class="loading.title_class" :is="loading.title_tag || 'h3'" :style="{'color': loading.text_color}" title>
            {{loading.title}}
          </component>
          <img v-if="loading.image" class="image my-2" :alt="loading.image.alt" :src="loading.image.url"/>
          <component class="pt-3" :is="loading.description_tag || 'p'" :class="loading.description_class" v-if="loading.description" :style="{'color': `${loading.text_color}`}" v-html="loading.description" description></component>
        </div>
        <div v-else-if="!completed" class="col-12">
          <component :class="loading.title_class" :is="loading.title_tag || 'h3'" :style="{'color': loading.text_color}" title>
            {{ft("globals.test_interaction.incomplete_title")}}
          </component>
          <component class="pt-3" :is="loading.description_tag || 'p'" :class="loading.description_class" :style="{'color': `${loading.text_color}`}" description>
            {{ft("globals.test_interaction.incomplete_message")}}
          </component>
          <a
            v-if="showRestartBtn"
            class="btn btn-primary my-3"
            href="javascript: void(0)"
            @click.prevent="$emit('interaction-handler', { action: 'restart' })"
          >
            {{ft("globals.test_interaction.restart")}}
          </a>
        </div>
      </div>
    </div>
  </intersect>
</template>

<style lang="scss" scoped>
[test-interaction-component] {
  .loading-container {
    .image {
      width: 320px;
    }
  }
}
</style>

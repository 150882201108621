<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  props: {
    customShareSlug: {
      type: String,
      default: ""
    },
    titleClass: {
      type: String,
      default: "h2"
    },
    badges: {
      type: Array,
      default: null
    }
  },
  methods: {
    facebookUrl: Fandom.facebookUrl,
    getButtonClass: Fandom.getButtonClass,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    getSecondaryButtonClass: Fandom.getSecondaryButtonClass,
    mayOpenVideoPopup(event, button) {
      if (button.url === "$video") {
        event.stopPropagation();
        event.preventDefault();
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
      }
    },
  },
  computed: {
    iconWidth() {
      return {
        width: get(this.content, "icon_width", "6rem")
      }
    },
    textAlign() {
      // Allowed values: left, right and center.
      return (this.content.text_align) ? `text-${this.content.text_align}` : null;
    },
    hasShareInteraction() {
      return get(this, "content.share.interaction.type", false) === "Share";
    },
    category() {
      return Fandom.exists(this.content.category) ? this.content.category : this.content.miniformat_title;
    },
    signInRequired() {
      return !Fandom.isUserRegistered() && this.content.enable_sign_in;
    },
    buttonsRequired() {
      return (this.content.button || this.content.buttons) && !this.signInRequired;
    },
    mainClasses() {
      return [
        this.textAlign
      ]
    }
  }
};
</script>

<template>
  <div :class="[mainClasses]" class="my-4" text-section-component>
    <img v-if="content.icon" class="icon my-4" :alt="content.icon.alt" :src="content.icon.url" :style="iconWidth">
    <p v-if="category" class="category">{{category}}</p>
    <div class="d-flex mb-4" v-if="badges">
      <div class="badge mr-2 text-uppercase font-weight-bold bg-dark" :class="`text-${badge.color}`" v-for="(badge, key) in badges" :key="`badge-${contentId}-${key}`"
      > {{badge.title}} </div>
    </div>
    <component v-if="content.title" :class="content.title_class || titleClass" :is="content.title_tag || 'h2'">
      {{content.title}}
    </component>
    <component v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" class="h2">{{content.subtitle}}</component>
    <component :is="content.description_tag || 'div'" class="description" v-if="content.description" v-html="content.description"></component>
    <div v-if="buttonsRequired" class="align-btn-group my-4">
      <a 
        v-if="content.button"
        @click="(e) => {mayOpenVideoPopup(e, content.button)}"
        class="btn shadow-none m-2 my-2" 
        :class="getPrimaryButtonClass(content.button_class, content.theme)" 
        :target="getTarget(content.button)" 
        :href="content.button.url"
      >
        {{content.button.title}}
      </a>
      <template v-if="content.buttons">
        <a 
          class="btn shadow-none m-2" 
          v-for="(buttonRecord, index) in content.buttons"
          @click="(e) => {mayOpenVideoPopup(e, buttonRecord.button)}"
          :key="index" 
          :class="getPrimaryButtonClass(buttonRecord.button_class, content.theme)" 
          :target="getTarget(buttonRecord.button)" 
          :href="buttonRecord.button.url"
        >
          {{buttonRecord.button.title}}
        </a>
      </template>
    </div>
    <div v-if="signInRequired" class="sign-in-section d-flex flex-wrap justify-content-center flex-column">
      <div class="sign-in-button-group-section">
        <a class="btn shadow-none btn-dark mr-md-2 my-2 col-8 col-md-auto" :href="applyContextToUrl('/users/sign_up')">
          {{ft("globals.login.sign_up")}}
        </a>
        <a class="btn shadow-none btn-facebook ml-md-2 my-2 col-8 col-md-auto" :href="facebookUrl()">
          {{ft("globals.login.facebook")}}
        </a>
      </div>
      <p v-html="ft('globals.login.sign_in')"></p>
    </div>
    <share-icons 
      class="align-btn-group my-4 m-0" 
      v-if="hasShareInteraction" 
      :content="content" 
      :container-content="containerContent" 
      :custom-share-slug="customShareSlug"
    ></share-icons>
  </div>
</template>

<style lang="scss" scoped>
[text-section-component] {

  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .icon {
    max-width: 100%;
    width: 6rem;
  }
}
</style>
<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { shadoEditorialMixin } from '../../modules/shado_mixins_module'

export default {
  mixins: [fandomBasicMixin, shadoEditorialMixin],
  props: ["optTextAlign"],
  data() {
    return {
      defaultTextAlignment: "center",
    }
  },
  mounted() { },
  methods: { },
  computed: {
    textAlign() {
      return this.optTextAlign ? this.optTextAlign : this.content.text_align ? this.content.text_align : 'center';
    }
  },
};
</script>

<template>
  <div class="container" stripe-header-component>
    <div class="row" :class="flexAlignmentClass">
      <div 
        class="col-12 col-md-6" 
        :class="[columnClass, `text-${textAlign}`]"
      >
        <div :class="content.title_class ? content.title_class : 'h1'" v-if="content.title">{{ content.title }}</div>
        <p v-if="content.description" v-html="content.description"></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-header-component] {
}
</style>
<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {}
  },
  methods: {
    recaptchaIsActive() {
      return !!globalState.pageModel.aux.recaptcha_enterprise;
    },
    isItemActive(item) {
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    hiddenItem(item) {
      return !(item.url.url.split("$")[1] === "empty");
    },
    getLangUrl(lang) {
      const content = this.pageModel.name_to_content[this.pageModel.main_content_name]
      return content ? `/${lang}${content.slug}` : `/${lang}`;
    }
  }
};
</script>

<template>
  <div class="w-100 bg-dark" vue-footer-component>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex flex-column flex-sm-row justify-content-between align-items-center py-5">
          <div class="footer__logo my-5 my-sm-0" v-if="content.logo">
            <img :src="content.logo.url">
          </div>
          <div v-if="content.menu">
            <ul class="d-flex list-unstyled footer_menu mb-0 flex-column flex-sm-row text-center">
              <li v-for="item in content.menu" class="mx-3 my-2 my-sm-0" v-if="hiddenItem(item)">
                <a :href="item.url.url" :target="getTarget(item.url)" class="text-decoration-none text-white footer_menu__item" :class="{'font-weight-bold': isItemActive(item)}">{{item.url.title}}</a>
              </li>
            </ul>
          </div>
          <div class="d-flex flex-column flex-sm-row">
            <div class="footer_social-icon-container mr-sm-5 my-4 my-sm-0">
              <a v-for="icon in content.social_icons" class="footer_social-icon link-light-grey text-decoration-none mx-4" :href="icon.url.url" :target="getTarget(icon.url)"><i class="fab" :class="icon.icon"></i></a>
            </div>
            <div class="footer_langs-container footer_langs-container d-flex mx-3 text-uppercase text-light-grey align-items-center justify-content-center my-4 my-sm-0">
              <a :href="getLangUrl('en')" class="footer_lang-link link-light-grey" :class="{'font-weight-bold active': globalState.lang === 'en'}">en</a>
              <div class="text-white px-3">|</div>
              <a :href="getLangUrl('it')" class="footer_lang-link link-light-grey" :class="{'font-weight-bold active': globalState.lang === 'it'}">it</a>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between py-5 px-3 flex-column flex-sm-row">
          <div class="footer_separator bg-light-grey"></div>
          <div class="footer__disclaimer-container text-sm-left text-center my-4 my-sm-0">
            <p class="footer__disclaimer text-light-grey mb-0">{{content.address}}</p>
            <p class="small text-light-grey" v-if="recaptchaIsActive()">
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">Privacy Policy</a> and
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
          </div>
          <div class="footer__address-container text-sm-left text-center my-4 my-sm-0">
            <p class="footer__address text-light-grey mb-0">{{content.disclaimer}}</p>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[vue-footer-component] {

  .footer_separator {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: 0;
  }

  .footer__disclaimer, .footer__address, .footer_menu__item {
    font-size: 0.85rem;
  }

  .footer__logo {
    height: $logo-height;

    img {
      max-height: 100%;
      width: auto;
    }
  }

  .footer_lang-link {
    font-size: 0.85rem;

    &.active {
      color: $white !important;
    }
  }

  .footer_social-icon {
    i {
      font-size: 1.5rem;
    }
  }
  
  @include media-breakpoint-down(xs) {

    .footer_lang-link {
      font-size: 1rem;
    }

    .footer__disclaimer, .footer__address, .footer_menu__item {
      font-size: 1rem;
    }

    .footer_social-icon {
      i {
        font-size: 2rem;
      }
    }
  }

}
</style>
<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  props: {
    interaction: Object,
    points: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getInteractionFeedbackText() {
      if (this.isInteractionFeedbackPhase(this.containerContent, this.interaction)) {
        return this.ft("globals.interaction.feedback_success_text");   
      }
    },
    getGainedPointsText() {
      return this.ft("globals.gained_points", {points: this.points});
    }
  }
};
</script>

<template>
  <div class="fixed-top d-flex align-items-center justify-content-center mx-auto py-3 px-3 mt-3" interaction-feedback-alert>
    <div class="feedback-icon mr-3">
      <i class="fas fa-thumbs-up"></i>
    </div>
    <div>
      <h2 class="feedback-text mb-0">{{getInteractionFeedbackText}}</h2>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$feedback-small-text-font-size: 0.75rem;
$feedback-text-font-size: 1rem;
$feedback-icon-font-size: 1.5rem;
$feedback-width: 30rem;
$feedback-background-color: #F8F9FA;
$feedback-border-color: #DEE2E6;
$feedback-text-color: black;

[interaction-feedback-alert] {
  z-index: $zindex-feedback;
  width: $feedback-width;
  max-width: 100%;
  border-radius: 15rem;
  background-color: $feedback-background-color;
  border: 1px solid $feedback-border-color;

  .feedback-points-description {
    color: $feedback-text-color;
    font-size: $feedback-small-text-font-size;
  }

  .feedback-text {
    font-size: $feedback-text-font-size;
    font-weight: normal;
    color: $feedback-text-color;
  }

  i {
    font-size: $feedback-icon-font-size;
    color: green;
  }

}
</style>

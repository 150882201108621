<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    tileAspectRactioClass: {
      type: String,
      default: "ar-9by16"
    }
  },
  data() {
    return {
      carousel: null
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (Fandom.exists(this.content.children)) {
        this.carousel = new Flickity(this.$refs["flickity-carousel"], {
          draggable: ">1",
          prevNextButtons: false,
          pageDots: (this.content.children.length > 1),
          cellAlign: "center",
          wrapAround: true,
        });

        this.carousel.on("dragStart", (event) => {
          event.preventDefault();
          $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'none');
        });

        this.carousel.on("dragEnd", (event) => {
          event.preventDefault();
          $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'all');
        });
      }
    });
  },
  computed: {
    pagerClass() {
      return `per-page-${get(this, "content.per_page", 4)}`;
    },
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :style="theme" class="py-5 themed-item" stripe-carousel-component>
    <div class="container">
      <stripe-header :content="content" class="pb-5"></stripe-header>
    </div>
    <div class="container-fluid px-0">
      <div class="flickity-carousel" ref="flickity-carousel" :class="pagerClass">
        <div class="flickity-carousel-item" v-for="(child, index) in content.children" :key="`carousel-slide-${index}`" :child_name="child">
          <div class="mx-3">
            <div class="position-relative" :class="tileAspectRactioClass">
              <component :is="getContent(child).layout" :content="getContent(child)" :animation="false"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-5" v-if="content.button">
      <div class="row justify-content-center my-4">
        <a class="btn" :class="getPrimaryButtonClass(content.button_class)" :href="content.button.url" :target="getTarget(content.button)">
          {{content.button.title}}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-carousel-component] {
  position: relative;

  .ar-9by16 {
    padding-bottom: 166.55%;
  }

  .flickity-page-dots {
    bottom: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .flickity-carousel-item {
    width: 80%;
    margin: 0 0;
  }

  @include media-breakpoint-down(xs) {
    /* Forced square rappresentation */
    .ar-16by9,
    .ar-4by3 {
      padding-bottom: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    @for $i from 1 through 6 {
      .per-page-#{$i} {
        .flickity-carousel-item {
          width: 100% / $i;
        }
      }
    }
  }
}
</style>
